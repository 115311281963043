var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-alert",
    {
      ref: "alertRef",
      attrs: {
        variant: _vm.alertVariant,
        dismissible: "",
        show: _vm.showError,
      },
      on: { dismissed: _vm.dismissAlert },
    },
    [
      _c("font-awesome-icon", { attrs: { icon: _vm.alertIcon } }),
      _vm._v("  " + _vm._s(_vm.msg) + " "),
      _vm.showErrorDetail
        ? [
            _vm._v("  ("),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    _vm.detailModalShow = true
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("alert.link_details")))]
            ),
            _vm._v(") "),
          ]
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("alert.title_error_details"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              _vm.detailModalShow = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.detailModalShow,
            callback: function ($$v) {
              _vm.detailModalShow = $$v
            },
            expression: "detailModalShow",
          },
        },
        [
          _c(
            "b-row",
            [
              _vm.showErrorDetailTitle
                ? [
                    _c("b-col", { attrs: { cols: "12" } }, [
                      _c("h6", [_vm._v(_vm._s(_vm.detailTitle))]),
                    ]),
                  ]
                : _vm._e(),
              _vm._l(_vm.details, function (item, index) {
                return [
                  _c("b-col", { key: index, attrs: { cols: "12" } }, [
                    _vm._v(_vm._s(item)),
                  ]),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }